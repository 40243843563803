import React from 'react';
import default_background from "assets/images/background.png";
import { isArrayNotEmpty } from 'helper/validations';
import ContactApp from 'components/ecard/ContactApp';
import BackButton from 'components/ecard/BackButton';


export default function ContactDisplay({
   bgOverlayStyle,

    titleStyle,

    nameStyle, relationshipStyle, borderStyle, 

    contacts,
}) {
    return (
        <div className="general_background m-auto">

            <div className="contact_component d-flex flex-column">
                <div className="contact_header d-flex justify-content-between align-items-center">
                    <div className="contact_header_back">
                        <BackButton />
                    </div>
                    <div className="contact_header_title text-center">
                        <div className="header2">
                        <h4 id="tajuk" style={titleStyle}>
                        HUBUNGI
                        </h4>
                        </div>
                    </div>
                    <div className="contact_header_empty text-right"></div>
                </div>
            
                <div className="contact__container m-auto" style={bgOverlayStyle}>
                    <div className="inner__container">
    

                        <div className="container__person m-auto">
                            {isArrayNotEmpty(contacts) && contacts.map((contact) => (
                                // REFACTOR: props below simplified to {...contact} due to same name
                                <ContactApp
                                    name={contact?.name}
                                    phoneNo={contact?.phoneNo?.replaceAll(/[()\s-]+/g, "")}
                                    relationship={contact?.relationship}

                                    borderStyle={borderStyle}
                                    nameStyle={nameStyle}
                                    relationshipStyle={relationshipStyle} 
                                />
                            ))}
                        </div>
                    </div>

                    <div className="pishang">
                        <div className="followtitle">
                            <h6 className="contact__text6">Ikuti Kami Melalui:</h6>
                        </div>

                        <div className="wrapper">
                            <div className="mediabutton">
                            <a href={`http://` + window?.location?.host + `/hubungi-kami`} target="_blank" rel="noopener noreferrer">
                                <div className="mediaicon">
                                <i className="far fa-envelope"></i>
                                </div>
                            </a>
                            </div>

                            <div className="mediabutton">
                            <a href={`https://wa.me/601116634896`} target="_blank" rel="noopener noreferrer">
                                <div className="mediaicon">
                                <i className="fab fa-whatsapp ws"></i>
                                </div>
                            </a>
                            </div>

                            <div className="mediabutton">
                            <a
                                href={`https://www.facebook.com/Ekad-Tepi-105124631441865`}
                                target="_blank" rel="noopener noreferrer"
                            >
                                <div className="mediaicon">
                                <i className="fab fa-facebook-f fb"></i>
                                </div>
                            </a>
                            </div>

                            <div className="mediabutton">
                            <a href={`https://www.instagram.com/ekadtepimy`} target="_blank" rel="noopener noreferrer">
                                <div className="mediaicon">
                                <i className="fab fa-instagram ig"></i>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}