import React from "react";
import default_background from "assets/images/background.png";
import { ImgWithFallback } from "components";
import moment from "moment";
import "moment/locale/ms";
import {
  convertToDate,
  getMalayIslamicDateString,
  isDateValid,
} from "helper/validations/date";
import { isStringNotEmpty } from "helper/validations";

export default function DetailsDisplay({
  bgImg,
  fallbackImg,
  bgOverlayStyle,
  opacityStyle,

  textStyle,
  titleStyle,

  name1,
  name2,
  celebratorStyle,

  invitee, // ISSUE: this has not been implemented in Ecard yet.
  inviteeStyle, // ISSUE: this has not been implemented in Ecard yet.

  spouse1,
  spouse2,
  spouseStyle,

  date1,
  date2,
  dateStyle,

  place,
  placeStyle,

  prayerText,
  prayerStyle,
  
  eventType = "perkahwinan",
  honoreeTitle = "anak"
}) {
  return (
    <div className="general_background">
      <ImgWithFallback
        style={opacityStyle}
        src={bgImg}
        fallback={fallbackImg ? fallbackImg : default_background}
      />

      <div className="details__container m-auto">
        {/* REFACTOR: remove all the style in div. 
                                Then, put the style only on the div itself 
                                & style will be automatially be applied to all 
                */}
        <div className="details__content" style={bgOverlayStyle}>
          <p className="b" id="parents" style={textStyle}>
            <strong style={celebratorStyle}>{name1}</strong>
            <br />{isStringNotEmpty(name1) && isStringNotEmpty(name2) && ("&")} <br /> <strong style={celebratorStyle}>{name2}</strong>
          </p>
          <p className="b" id="b1" style={textStyle}>
            Dengan segala hormatnya menjemput
          </p>
          <p className="b" id="b2" style={textStyle}>
            Datuk/Dato/Datin/Tuan/Puan/Cik
          </p>
          <p className={"b"} id="invitee">
            <strong style={inviteeStyle}>{invitee}</strong>
          </p>
          {/* <hr width="60%" /> */}
          <p className="b" id="serta" style={textStyle}>
            serta keluarga untuk ke majlis {eventType} {honoreeTitle} kami
          </p>
          <p className="b" id="pengantin" style={textStyle}>
            <strong style={spouseStyle}>{spouse1}</strong>
            <br /> {isStringNotEmpty(spouse1) && isStringNotEmpty(spouse2) && "dan"} <br />
            <strong style={spouseStyle}>{spouse2}</strong>
          </p>
          <p className="b" id="tarikh" style={textStyle}>
            Pada hari{" "}
            <strong style={dateStyle}>
              {isDateValid(date1) &&
                moment(convertToDate(date1))
                  .locale("ms")
                  .format("dddd DD MMM, YYYY")}
            </strong>{" "}
            bersamaan{" "}
            <strong style={dateStyle}>
              {isDateValid(date2) && getMalayIslamicDateString(date2)}
            </strong>
          </p>
          <p className="b" id="tempat" style={textStyle}>
            bertempat di <strong style={placeStyle}>{place}</strong>
          </p>
          <p className="b" id="doa" style={prayerStyle}>
            {prayerText}
          </p>
          <p className="b" id="thanks" style={textStyle}>
            Terima Kasih
          </p>
        </div>
      </div>
    </div>
  );
}
